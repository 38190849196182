/**
 * auth: 需要鉴权的页面设为true,不加或值为false则该页面不检验登入状态
 */
import { lazy } from 'react'

const routes = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('@@/page/home')),
    sceneConfig: {
      enter: 'none',
      exit: 'none'
    }
  },
  {
    path: '/home',
    exact: true,
    component: lazy(() => import('@@/page/home')),
    sceneConfig: {
      enter: 'none',
      exit: 'none'
    }
  },
  {
    auth: true,
    path: '/draw',
    component: lazy(() => import('@@/page/draw')),
    sceneConfig: {
      enter: 'none',
      exit: 'none'
    }
  },
  {
    auth: true,
    path: '/betslip',
    component: lazy(() => import('@@/page/betslip')),
    sceneConfig: {
      enter: 'none',
      exit: 'none'
    }
  },
  {
    auth: true,
    path: '/gameDetails/:title/:platform/:menu_id',
    component: lazy(() => import('@@/page/home/game/detail'))
  },
  {
    path: '/gameSearch/:platform/:menu_id',
    auth: true,
    component: lazy(() => import('@@/page/home/game/gameSearch'))
  },
  {
    auth: true,
    path: '/serivce',
    component: lazy(() => import('@@/page/serivce')),
    sceneConfig: {
      enter: 'none',
      exit: 'none'
    }
  },
  {
    auth: true,
    path: '/my',
    component: lazy(() => import('@@/page/my')),
    sceneConfig: {
      enter: 'none',
      exit: 'none'
    }
  },
  {
    auth: true,
    path: '/betting/:title/:category/:code',
    component: lazy(() => import('@@/page/betting'))
  },
  {
    auth: true,
    path: '/recharge',
    component: lazy(() => import('@@/page/my/recharge'))
  },
  {
    auth: true,
    path: '/chatroom',
    component: lazy(() => import('@@/page/my/chatroom'))
  },
  {
    auth: true,
    path: '/withdraw',
    component: lazy(() => import('@@/page/my/withdraw'))
  },
  {
    path: '/orderList',
    auth: true,
    component: lazy(() => import('@@/page/my/history/orderList'))
  },
  {
    path: '/orderListHistory',
    auth: true,
    component: lazy(() => import('@@/page/my/history/index'))
  },
  {
    path: '/orderInfo',
    auth: true,
    component: lazy(() => import('@@/page/my/history/orderInfo'))
  },
  {
    auth: true,
    path: '/message',
    component: lazy(() => import('@@/page/my/message'))
  },
  {
    auth: true,
    path: '/helpCenter',
    component: lazy(() => import('@@/page/my/helpCenter'))
  },
  {
    auth: true,
    path: '/setting',
    component: lazy(() => import('@@/page/my/setting'))
  },
  {
    auth: true,
    path: '/messageDetails/:type',
    component: lazy(() => import('@@/page/my/message/detail'))
  },
  {
    auth: true,
    path: '/helpCenterDetails/:title/:id',
    component: lazy(() => import('@@/page/my/helpCenter/detail'))
  },
  {
    auth: true,
    path: '/newPeronalStudy/:id',
    component: lazy(() => import('@@/page/serivce/newPeronalStudy'))
  },
  {
    auth: true,
    path: '/edit',
    component: lazy(() => import('@@/page/my/edit'))
  },
  {
    auth: true,
    path: '/purse',
    component: lazy(() => import('@@/page/my/purse'))
  },
  {
    auth: true,
    path: '/homCenter',
    component: lazy(() => import('@@/page/my/purse/homeCenter'))
  },
  {
    auth: true,
    path: '/settingInfo',
    component: lazy(() => import('@@/page/my/setting/settingInfo'))
  },
  {
    path: '/forgetPwd',
    component: lazy(() => import('@@/page/my/safeCenter/forgetPwd'))
  },
  {
    auth: true,
    path: '/changepassword',
    component: lazy(() => import('@@/page/my/safeCenter/changePassword'))
  },
  {
    auth: true,
    path: '/nickName',
    component: lazy(() => import('@@/page/my/edit/nickName'))
  },
  {
    auth: true,
    path: '/safeCenter',
    component: lazy(() => import('@@/page/my/safeCenter'))
  },
  {
    path: '/authentication',
    exact: true,
    component: lazy(() => import('@@/page/my/safeCenter/authentication')),
  },
  {
    path: '/authenticationSet',
    exact: true,
    component: lazy(() => import('@@/page/my/safeCenter/set/index')),
  },
  {
    path: '/authenticationSetDetail',
    exact: true,
    component: lazy(() => import('@@/page/my/safeCenter/set/detail/index')),
    sceneConfig: {
      enter: 'none',
      exit: 'none'
    }
  },
  {
    auth: true,
    path: '/googleAuth',
    component: lazy(() => import('@@/page/my/safeCenter/authentication/google'))
  },
  {
    auth: true,
    path: '/microsoftAuth',
    component: lazy(() => import('@@/page/my/safeCenter/authentication/microsoft'))
  },
  {
    auth: true,
    path: '/authenticationTeach',
    component: lazy(() => import('@@/page/my/safeCenter/authentication/teach/index'))
  },
  {
    auth: true,
    path: '/email/:title/:id',
    component: lazy(() => import('@@/page/my/safeCenter/email'))
  },
  {
    auth: true,
    path: '/mobile/:title/:id',
    component: lazy(() => import('@@/page/my/safeCenter/mobile'))
  },
  {
    auth: true,
    path: '/mobileValidation/:title/:id',
    component: lazy(() => import('@@/page/my/safeCenter/mobileValidation'))
  },
  {
    auth: true,
    path: '/bankManage',
    component: lazy(() => import('@@/page/my/purse/bankManage'))
  },
  {
    auth: true,
    path: '/bankiopManage',
    component: lazy(() => import('@@/page/my/purse/bankManage'))
  },
  {
    auth: true,
    path: '/speedypayManage',
    component: lazy(() => import('@@/page/my/purse/bankManage'))
  },
  {
    auth: true,
    path: '/addBanks',
    component: lazy(() => import('@@/page/my/purse/bankManage/addBanks'))
  },
  {
    auth: true,
    path: '/alipay',
    component: lazy(() => import('@@/page/my/purse/alipayManage/addAlipay'))
  },
  {
    auth: true,
    path: '/alipayManage',
    component: lazy(() => import('@@/page/my/purse/alipayManage'))
  },
  {
    auth: true,
    path: '/wechatAdd',
    component: lazy(() => import('@@/page/my/purse/wechatManage/addWechat'))
  },
  {
    auth: true,
    path: '/wechatManage',
    component: lazy(() => import('@@/page/my/purse/wechatManage'))
  },
  {
    auth: true,
    path: '/usdtManage',
    component: lazy(() => import('@@/page/my/purse/usdtManage'))
  },
  {
    auth: true,
    path: '/addUsdtAddress',
    component: lazy(() => import('@@/page/my/purse/usdtManage/addUsdt'))
  },
  {
    auth: true,
    path: '/ubitcoinManage',
    component: lazy(() => import('@@/page/my/purse/ubitcoinManage'))
  },
  {
    auth: true,
    path: '/addUbit',
    component: lazy(() => import('@@/page/my/purse/ubitcoinManage/addUbit'))
  },
  // {
  //   auth: true,
  //   path: '/addTopayAddress',
  //   component: lazy(() => import('@@/page/my/purse/topayManage/addTopayAddress'))
  // },
  {
    auth: true,
    path: '/topayManage',
    component: lazy(() => import('@@/page/my/purse/topayManage'))
  },
  {
    auth: true,
    path: '/addTopayAddress',
    component: lazy(() => import('@@/page/my/purse/topayManage/addTopay'))
  },
  {
    auth: true,
    path: '/ebpayManage',
    component: lazy(() => import('@@/page/my/purse/ebpayManage'))
  },
  {
    auth: true,
    path: '/addEbpayAddress',
    component: lazy(() => import('@@/page/my/purse/ebpayManage/addEbpay'))
  },

  {
    auth: true,
    path: '/jdpayManage',
    component: lazy(() => import('@@/page/my/purse/jdpayManage'))
  },
  {
    auth: true,
    path: '/addJdpayAddress',
    component: lazy(() => import('@@/page/my/purse/jdpayManage/addJdpay'))
  },
  {
    auth: true,
    path: '/kdpayManage',
    component: lazy(() => import('@@/page/my/purse/kdpayManage'))
  },
  {
    auth: true,
    path: '/addKdpayAddress',
    component: lazy(() => import('@@/page/my/purse/kdpayManage/addKdpay'))
  },

  {
    auth: true,
    path: '/moneyPassword',
    component: lazy(() => import('@@/page/my/safeCenter/moneyPassword'))
  },
  {
    path: '/login',
    component: lazy(() => import('@@/page/login'))
  },
  {
    path: '/loginSafe',
    component: lazy(() => import('@@/page/login/safe'))
  },
  {
    path: '/loginCommon',
    component: lazy(() => import('@@/page/login/common'))
  },
  {
    path: '/reg',
    component: lazy(() => import('@@/page/reg'))
  },
  {
    path: '/topayWebview',
    auth: true,
    component: lazy(() => import('@@/page/my/recharge/topayWebview'))
  },
  {
    path: '/swiperDetails',
    auth: true,
    component: lazy(() => import('@@/page/home/lottery/banner/swiperDetails'))
  },

  {
    path: '/activity',
    auth: true,
    component: lazy(() => import('@@/page/home/activity'))
  },
  {
    path: '/feeNote',
    auth: true,
    component: lazy(() => import('@@/page/my/recharge/feeNote'))
  },
  {
    path: '/rechargeResult',
    auth: true,
    component: lazy(() => import('@@/page/my/history/rechargeResult'))
  },
  {
    path: '/kefu',
    component: lazy(() => import('@@/page/kefu'))
  }
]
// export {routes}
export default routes
